export const NSA_DEPARTMENT_MOCK_DATA = {
    departments: [
        {
            name: 'Special Units',
            patientsCount: 0,
            hoursDelay: 0,
            longestWaiting: '0d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 0,
                },
            ],
            waitingCategories: [],
        },
        {
            name: 'HAD',
            patientsCount: 0,
            hoursDelay: 0,
            longestWaiting: '0d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 0,
                },
            ],
            waitingCategories: [],
        },
        {
            name: 'Geriatrics',
            patientsCount: 35,
            hoursDelay: 24,
            longestWaiting: '676d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 35,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 15b - User awaiting housing order',
                    count: 3,
                },
                {
                    category: 'Var 12b - Long term care (Not charged)',
                    count: 14,
                },
                {
                    category: 'Var 14 - Sante Mentale',
                    count: 1,
                },
                {
                    category: 'Other',
                    count: 1,
                },
                {
                    category:
                        'Var 12a - Ressources de type Famille ou Intermediaire RTF/RI',
                    count: 4,
                },
                {
                    category: 'Var 08b - Evaluation in Progress',
                    count: 7,
                },
                {
                    category: 'Var 08c - Orientation Pending',
                    count: 5,
                },
            ],
        },
        {
            name: 'Neurology',
            patientsCount: 6,
            hoursDelay: 4,
            longestWaiting: '26d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 4,
                },
                {
                    range: '>10d',
                    count: 2,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 10a - Intense rehab',
                    count: 4,
                },
                {
                    category: 'Var 08c - Orientation Pending',
                    count: 2,
                },
            ],
        },
        {
            name: 'Palliative',
            patientsCount: 0,
            hoursDelay: 0,
            longestWaiting: '0d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 0,
                },
            ],
            waitingCategories: [],
        },
        {
            name: 'Psychiatry',
            patientsCount: 9,
            hoursDelay: 6,
            longestWaiting: '695d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 9,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 15b - User awaiting housing order',
                    count: 2,
                },
                {
                    category: 'Var 14 - Sante Mentale',
                    count: 4,
                },
                {
                    category: 'Var 08c - Orientation Pending',
                    count: 2,
                },
                {
                    category:
                        'Var 12a - Ressources de type Famille ou Intermediaire RTF/RI',
                    count: 1,
                },
            ],
        },
        {
            name: 'Surgery',
            patientsCount: 8,
            hoursDelay: 5,
            longestWaiting: '115d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 3,
                },
                {
                    range: '>10d',
                    count: 5,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 10a - Intense rehab',
                    count: 2,
                },
                {
                    category: 'Var 12b - Long term care (Not charged)',
                    count: 1,
                },
                {
                    category: 'Var 10b - Moderate Rehab',
                    count: 2,
                },
                {
                    category: 'Var 08b - Evaluation in Progress',
                    count: 2,
                },
                {
                    category: 'Var 08c - Orientation Pending',
                    count: 1,
                },
            ],
        },
        {
            name: 'SSU',
            patientsCount: 1,
            hoursDelay: 1,
            longestWaiting: '27d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 1,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 08b - Evaluation in Progress',
                    count: 1,
                },
            ],
        },
        {
            name: 'Cardiology',
            patientsCount: 2,
            hoursDelay: 1,
            longestWaiting: '68d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 2,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 08b - Evaluation in Progress',
                    count: 1,
                },
                {
                    category: 'Var 10a - Intense rehab',
                    count: 1,
                },
            ],
        },
        {
            name: 'IM Teach',
            patientsCount: 2,
            hoursDelay: 2,
            longestWaiting: '37d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 2,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 09 - Home Services required',
                    count: 1,
                },
                {
                    category: 'Var 08c - Orientation Pending',
                    count: 1,
                },
            ],
        },
        {
            name: 'Family Med',
            patientsCount: 0,
            hoursDelay: 0,
            longestWaiting: '0d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 0,
                },
            ],
            waitingCategories: [],
        },
        {
            name: 'IM non Teach',
            patientsCount: 0,
            hoursDelay: 0,
            longestWaiting: '0d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 0,
                },
            ],
            waitingCategories: [],
        },
        {
            name: 'Oncology',
            patientsCount: 5,
            hoursDelay: 1,
            longestWaiting: '154d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 5,
                },
            ],
            waitingCategories: [
                {
                    category: 'Var 12b - Long term care (Not charged)',
                    count: 1,
                },
                {
                    category: 'Var 08c - Orientation Pending',
                    count: 2,
                },
                {
                    category: 'Var 08b - Evaluation in Progress',
                    count: 1,
                },
                {
                    category: 'Var 09 - Home Services required',
                    count: 1,
                },
            ],
        },
        {
            name: 'Critical Care',
            patientsCount: 0,
            hoursDelay: 0,
            longestWaiting: '0d',
            waitingDays: [
                {
                    range: '0-5d',
                    count: 0,
                },
                {
                    range: '5-10d',
                    count: 0,
                },
                {
                    range: '>10d',
                    count: 0,
                },
            ],
            waitingCategories: [],
        },
    ],
}
