export const MSSS_NSA_PREDICTION_MOCK = {
    "prediction": {
        "Hor Deli": {
            "Other": [
                0,
                0,
                0
            ],
            "Var 08b - Evaluation in Progress": [
                8.384615384615385,
                8.569230769230769,
                8.753846153846155
            ],
            "Var 08c - Orientation Pending": [
                5.02197802197802,
                4.815384615384614,
                4.608791208791207
            ],
            "Var 09 - Home Services required": [
                2.3406593406593386,
                2.1956043956043936,
                2.050549450549448
            ],
            "Var 10a - Intense rehab": [
                0,
                0,
                0
            ],
            "Var 10b - Moderate Rehab": [
                0,
                0,
                0
            ],
            "Var 11 - Palliative": [
                0,
                0,
                0
            ],
            "Var 12a - Ressources de type Famille ou Intermediaire RTF/RI": [
                1.2747252747252724,
                1.0637362637362613,
                0.8527472527472502
            ],
            "Var 12b - Long term care (Not charged)": [
                18.252747252747252,
                17.41978021978022,
                16.586813186813185
            ],
            "Var 14 - Sante Mentale": [
                6.714285714285713,
                6.685714285714284,
                6.657142857142855
            ],
            "Var 15b - User awaiting housing order": [
                0,
                0,
                0
            ]
        },
        "Inpatient": {
            "Other": [
                2.43956043956044,
                2.6219780219780224,
                2.804395604395605
            ],
            "Var 08b - Evaluation in Progress": [
                17.71428571428572,
                18.371428571428577,
                19.028571428571432
            ],
            "Var 08c - Orientation Pending": [
                13.8021978021978,
                13.947252747252744,
                14.09230769230769
            ],
            "Var 09 - Home Services required": [
                9.021978021978022,
                9.043956043956044,
                9.065934065934066
            ],
            "Var 10a - Intense rehab": [
                5.505494505494502,
                5.153846153846151,
                4.8021978021977985
            ],
            "Var 10b - Moderate Rehab": [
                5.208791208791208,
                5.331868131868132,
                5.454945054945054
            ],
            "Var 11 - Palliative": [
                0.7802197802197801,
                0.7890109890109889,
                0.7978021978021976
            ],
            "Var 12a - Ressources de type Famille ou Intermediaire RTF/RI": [
                1.1318681318681305,
                0.8923076923076909,
                0.6527472527472513
            ],
            "Var 12b - Long term care (Not charged)": [
                22.593406593406584,
                21.643956043956035,
                20.694505494505485
            ],
            "Var 14 - Sante Mentale": [
                6.714285714285713,
                6.685714285714284,
                6.657142857142855
            ],
            "Var 15b - User awaiting housing order": [
                2.9999999999999996,
                2.9999999999999996,
                2.999999999999999
            ]
        },
        "Outpatient": {
            "Other": [
                0,
                0,
                0
            ],
            "Var 08b - Evaluation in Progress": [
                5.549450549450545,
                5.041758241758237,
                4.534065934065929
            ],
            "Var 08c - Orientation Pending": [
                0.769230769230769,
                0.7670329670329669,
                0.7648351648351646
            ],
            "Var 09 - Home Services required": [
                0,
                0,
                0
            ],
            "Var 10a - Intense rehab": [
                0,
                0,
                0
            ],
            "Var 10b - Moderate Rehab": [
                0,
                0,
                0
            ],
            "Var 11 - Palliative": [
                0,
                0,
                0
            ],
            "Var 12a - Ressources de type Famille ou Intermediaire RTF/RI": [
                0,
                0,
                0
            ],
            "Var 12b - Long term care (Not charged)": [
                6.032967032967034,
                6.3802197802197815,
                6.727472527472528
            ],
            "Var 14 - Sante Mentale": [
                0,
                0,
                0
            ],
            "Var 15b - User awaiting housing order": [
                0,
                0,
                0
            ]
        }
    }
}